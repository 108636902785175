import React, { useContext, useEffect, useState } from "react";
import { MediaContext } from "../../../store/context/media-context";
import Slider from "react-slick";
import RatingReviewPost from "./RatingReviewPost";
import RatingReviewTrust from "./RatingReviewTrust";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

const RatingReview = ({ posts, trustText }) => {
  const [isRendered, setIsRendered] = useState(false);
  const { isNotInitMobile } = useContext(MediaContext);
  useEffect(() => {
    setIsRendered(true);
  }, []);

  const settings = {
    className: "PgP-RatingReview-slider",
    centerMode: true,
    infinite: false,
    arrows: false,
    initialSlide: 0,
    speed: 500,
    touchThreshold: 40,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };
  return (
    <section className="PgP-RatingReview PgP-RatingReview-reviews">
      <div className="container">
        <div className="PgP-RatingReview-row">
          {isNotInitMobile && isRendered ? (
            <Slider {...settings}>
              <RatingReviewTrust trustText={trustText} />
              {posts.map(({ title, description, descriptionLong }) => (
                <RatingReviewPost
                  key={title}
                  title={title}
                  description={description}
                  descriptionLong={descriptionLong}
                />
              ))}
            </Slider>
          ) : (
            <>
              <RatingReviewTrust trustText={trustText} />
              {posts.map(({ title, description, descriptionLong }) => (
                <RatingReviewPost
                  key={title}
                  title={title}
                  description={description}
                  descriptionLong={descriptionLong}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default RatingReview;
